import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { formatDate } from '@hl-portals/helpers';

import { BoxTypes } from '../../Box';
import DatePicker from '../../DatePicker';
import InputGroup, { InputGroupProps } from '../../InputGroup';

export type DateInputProps = {
  name: string;
  label: string;
  testId?: string;
  containerProps?: BoxTypes & InputGroupProps;
};

export const DateInput = ({
  name,
  label,
  testId,
  containerProps,
}: DateInputProps) => {
  const { formState, setValue, clearErrors, watch } = useFormContext();
  const error = formState.errors[name];
  const value = watch(name);

  useEffect(() => {
    clearErrors(name);
  }, [value, name, clearErrors]);

  return (
    <InputGroup
      label={label}
      error={error?.message?.toString()}
      {...containerProps}
    >
      <DatePicker
        onDateSelect={(date) => {
          setValue(
            name,
            formatDate({
              dateString: date,
              dateFormat: 'MM/DD/YYYY',
            })
          );
        }}
        value={value}
        data-test={testId}
      />
    </InputGroup>
  );
};
