import { SizingProps, SpacingProps } from '@mui/system';

import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import Image from 'next/image';

import { colors } from '@hl-portals/constants';

import { useScreenSize } from '@hl-portals/hooks';

import { Box } from '../Box';
import { FileInput, FileInputProps } from '../FileInput';
import { Icon } from '../Icon';
import { Paragraph } from '../Typography';
import { useFileUpload } from './hooks/useFileUpload';
import { UploadedFilesList } from './styles';
import { FileUploadType, ISelectedFile } from './types';

const FileUploadList = ({
  selectedFilesRef,
  fileListPosition,
  fileType,
  selectedFiles,
  getFileInfo,
  onRemoveFile,
}: any) => {
  const { isMobile } = useScreenSize();

  return (
    <UploadedFilesList
      ref={selectedFilesRef}
      height={{
        xs: 1,
        md: '100%',
      }}
      width={{
        xs: 1,
        md: '100%',
      }}
      overflowY={{
        xs: 'visible',
      }}
      flexDirection="column"
      m={{
        xs: '24px 0 0',
        md: fileListPosition === 'right' ? '0 0 0 16px' : '24px 0 0',
      }}
      data-test="fileUploader-uploadedFiles"
    >
      <Paragraph color="coolGray2" variant="text" mb="16px">
        {`Selected ${fileType === 'document' ? 'files' : 'photos'} (${
          selectedFiles?.length
        })`}
      </Paragraph>
      {selectedFiles.map(
        ({ name: fileName, thumbnail, size, id }: any, index: any) => (
          <Box
            key={`${id || Math.floor(Math.random() * 1000)}-${fileName}`}
            width={{ xs: 1, md: 'unset' }}
            position="relative"
            justifyContent="space-between"
            alignItems="flex-start"
            mb="12px"
            p="12px 16px"
            border={`1px solid ${colors.coolGray5}`}
            borderRadius="12px"
            data-test="fileUploader-uploadedFiles-file"
          >
            <Box>
              <Box width="55px">
                <Image
                  src={
                    thumbnail && !isMobile ? thumbnail : '/icons/document.svg'
                  }
                  height={thumbnail ? 55 : 50}
                  width={thumbnail ? 55 : 40}
                  objectFit="contain"
                />
              </Box>
              <Box width={1} flexDirection="column" ml="12px">
                <Paragraph
                  variant="text-bold"
                  color="darkBlue"
                  width={1}
                  maxWidth={{
                    xs: '175px',
                    sm: '400px',
                    md: '200px',
                  }}
                  mb="2px"
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  title={fileName}
                >
                  {fileName}
                </Paragraph>
                <Paragraph variant="text-small" color="coolGray2">
                  {getFileInfo(fileName, size)}
                </Paragraph>
              </Box>
            </Box>
            <Box position="absolute" top="12px" right="12px" cursor="pointer">
              <Icon
                type="clearButton"
                size={24}
                onClick={() => onRemoveFile(index)}
              />
            </Box>
          </Box>
        )
      )}
    </UploadedFilesList>
  );
};
type FileUploaderProps = {
  /**
   * Specify whether document(s) or image(s) will be uploaded
   */
  fileType: FileUploadType;
  /**
   * Returns an array of the selected documents
   */
  onFileChange?: (result: Array<File>) => void;
  /**
   * (Only available when the 'multipleUpload' prop is enabled) Returns an updated array of the selected documents when a single file is removed.
   */
  onFileRemove?: (result: Array<File>, index: number) => void;
  /**
   * Error handler when something wrong happens.
   */
  onError?: (err: string) => void;
  /**
   * Error message
   */
  error?: string;
  /**
   * Lead for the file(s) to be attached to during STEP 3
   */
  leadId?: string;
  /**
   * Only perform file upload to S3, and return the file key(s) from S3.
   * Otherwise without this prop, the "process and create" step will run.
   */
  ignoreProcessAndCreateStep?: boolean;
  /**
   * Overrides the default upload behavior to a custom function
   * that receives the files from the input
   */
  customHandler?: (files: FileList) => void | Promise<void>;
  /**
   * The maximum file size in megabytes for the files to be uploaded. It throws an
   * error if use attempt to upload a file with size that exceeds maxFileSize
   */
  maxFileSize?: number;
  /**
   * Where the "Selected files" list will be displayed, relative to the file upload area
   */
  fileListPosition?: 'right' | 'bottom' | 'over';
  /**
   * Scroll into the "Selected files" list so that it's visible after file(s) upload
   */
  scrollIntoSelectedFilesView?: boolean;
  /**
   * Disable the file upload functionality
   */
  disabled?: boolean;
  /**
   * Save only unique files
   */
  uniqueOnly?: boolean;
  /**
   * Hide dropzone when in mobile view
   */
  hideOnMobile?: boolean;
  source?: string;
} & FileInputProps &
  SizingProps &
  SpacingProps;

const FileUploader = ({
  fileType,
  onFileChange,
  onFileRemove,
  accept,
  multipleUpload,
  leadId,
  ignoreProcessAndCreateStep,
  customHandler,
  maxFileSize,
  onError,
  error,
  fileListPosition = 'right',
  scrollIntoSelectedFilesView,
  disabled,
  uniqueOnly,
  hideOnMobile,
  source,
  ...rest
}: FileUploaderProps): React.ReactElement => {
  const selectedFilesRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useScreenSize();

  const { loading, percentProgress, selectedFiles, setSelectedFiles } =
    useFileUpload();

  useEffect(() => {
    if (
      scrollIntoSelectedFilesView &&
      selectedFiles &&
      selectedFilesRef?.current
    ) {
      selectedFilesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollIntoSelectedFilesView, selectedFilesRef, selectedFiles]);

  const validateFilesSizes = (files: Array<File>): boolean => {
    if (
      maxFileSize &&
      Array.from(files).some((f) => f.size > maxFileSize * 1000000)
    ) {
      toast(
        `The max size of each file cannot be larger than ${maxFileSize} MB`,
        {
          type: 'error',
        }
      );
      return false;
    }
    return true;
  };

  const onRemoveFile = (index: number) => {
    if (typeof index !== 'number' || selectedFiles?.length === 0) return;

    const newArray = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(newArray);

    if (onFileRemove) {
      onFileRemove(newArray, index);
    }
  };

  const onAddFiles = (files: ISelectedFile[]) => {
    setSelectedFiles((prev) => {
      if (!uniqueOnly) return [...prev, ...files];

      return [...prev, ...files].reduce((uniqueList, file) => {
        const { name, size } = file;
        const found = uniqueList.find(
          (uf) => uf.name === name && uf.size === size
        );

        if (found) return uniqueList;
        return [...uniqueList, file];
      }, [] as ISelectedFile[]);
    });
  };

  const handleOnFilesChange = async (files: FileList) => {
    const fileList: Array<ISelectedFile> = Array.from(files);

    if (maxFileSize) {
      const isValidFileSize = validateFilesSizes(fileList);
      if (!isValidFileSize) {
        return;
      }
    }

    if (fileType === 'image') {
      for (let i = 0, f; (f = files[i]); i++) {
        if (!f.type.match('image.*')) {
          continue;
        }

        const reader = new FileReader();

        reader.onload = (function () {
          return function (e) {
            fileList[i].thumbnail = String(e?.target?.result) || '';
            onAddFiles(fileList);
          };
        })();

        reader.readAsDataURL(f);
      }
    } else {
      onAddFiles(fileList);
    }

    if (onFileChange) {
      onFileChange(fileList);
    }
  };

  const getFileInfo = (name: string, size: number) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const fileSize = Number(size / 1000000).toFixed(2) + ' MB';
    const FILE_TYPE = String(name.split('.').pop()).toUpperCase();

    // TODO: Return `${fileSize} ${fileType}`
    // once the backend is responding with a valid file size

    return FILE_TYPE;
  };

  const fileListProps = {
    selectedFilesRef,
    fileListPosition,
    fileType,
    selectedFiles,
    getFileInfo,
    onRemoveFile,
    source,
  };

  return (
    <Box
      width={{
        xs: 1,
        md: '100%',
      }}
      flexDirection={{
        xs: 'column',
        md: fileListPosition === 'right' ? 'row' : 'column',
      }}
      {...rest}
    >
      {fileListPosition === 'over' && !!selectedFiles.length ? (
        <FileUploadList {...fileListProps} />
      ) : (
        <FileInput
          loading={loading}
          onFilesChange={customHandler ? customHandler : handleOnFilesChange}
          progress={percentProgress * 100}
          accept={accept}
          multipleUpload={multipleUpload}
          maxWidth={{ xs: 1, md: '600px' }}
          uploadButtonColor="secondary"
          disabled={disabled}
          display={isMobile && hideOnMobile ? 'none' : 'flex'}
          alignItems="center"
          mb="32px"
          {...rest}
        />
      )}
      {multipleUpload && !!selectedFiles?.length && (
        <FileUploadList {...fileListProps} />
      )}
      {error && (
        <Paragraph variant="text-small" color="cadmiumRed">
          {error}
        </Paragraph>
      )}
    </Box>
  );
};

export default FileUploader;
