import * as Sentry from '@sentry/nextjs';
import { useFlags } from 'flagsmith/react';

import { ReactNode } from 'react';
import { UseMutationOptions, useMutation } from 'react-query';
import { toast } from 'react-toastify';

import {
  ImprovedPropertyEligibilityModal,
  PropertyBlockedModal,
  PropertyEligibilityModal,
} from '@hl-portals/ui';

import { STATES } from '@hl-portals/constants';

import { config } from '@hl-portals/helpers';

import { useModal } from '@hl-portals/hooks';

import {
  AutomatedBbysEligibilityCheckArgs,
  AutomatedBbysPropertyDenialChecks,
  AutomatedBbysPropertyEligibilityBlockers,
  AutomatedBbysPropertyEligibilityChecks,
  AutomatedBbysPropertyWarningChecks,
  BlockerWarning,
  PropertyEligibilityChecksResponse,
  checkAutomatedPropertyEligibility,
} from './automated-property_eligibity-lender';

export type EligibilityCheck = keyof AutomatedBbysPropertyEligibilityChecks;
export type EligibilityBlocker = keyof AutomatedBbysPropertyEligibilityBlockers;
export type WarningCheck = keyof AutomatedBbysPropertyWarningChecks;
export type DenialCheck = keyof AutomatedBbysPropertyDenialChecks;

export const AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_CHECKS_LABELS: Record<
  keyof AutomatedBbysPropertyEligibilityChecks,
  string
> = {
  lot_size_check: "The property's lot size is more than 5 acres.",
  property_type_check:
    'The property is not a single-family home, townhome, or condo.',
  room_count_check:
    'The property does not have at least one bedroom and one bathroom.',
  zoning_description_check: 'The property is not zoned for residential.',
  property_value_check:
    'The property value is valued at over $2.5 million, which might limit the amount of equity we can access.',
  ltv_check:
    'The client might not have enough equity in the departing residence.',
};

export const AUTOMATED_BBYS_PROPERTY_WARNING_CHECKS_LABELS: Record<
  keyof AutomatedBbysPropertyWarningChecks,
  string[]
> = {
  low_equity_check: [
    'Low equity',
    'The loan-to-value ratio exceeds our maximum guideline of 70%.',
  ],
  rural_property_check: [
    'Location',
    'The property is not located in a micropolitan or metropolitan statistical area.',
  ],
  listed_DOM_check: [
    'Property is already listed',
    'The departing residence is actively listed and has been on the market for an extended period of time.',
  ],
  unrealistic_value_check: [
    'Property value',
    'The estimated value entered for the departing residence is significantly higher than what our models indicate. While your client should still qualify for Buy Before You Sell, the equity unlock loan might be limited.',
  ],
};

export const AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_BLOCKERS_LABELS: Record<
  keyof AutomatedBbysPropertyEligibilityBlockers,
  string | ReactNode
> = {
  lead_exists: (
    <BlockerWarning
      title="Property Already Submitted"
      description=" You’ve already submitted this property for Buy Before You Sell. Please check your email for the confirmation and next steps."
    />
  ),
};

export const AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_DENIALS_LABELS: Record<
  keyof AutomatedBbysPropertyDenialChecks,
  (value?: string) => string
> = {
  ineligible_state_check: (state) =>
    `HomeLight’s Buy Before You Sell program is currently not available in ${
      STATES[state as keyof typeof STATES]
    }`,
  ineligible_zip_check: (zipCode) =>
    `Our program is currently not available in ${zipCode}`,
  lot_size_check: () => 'The property lot size exceeds our limit of 5 acres',
  low_value_check: () =>
    'Our program is only available for properties valued at $100,000 or more',
  not_enough_equity_check: () =>
    `The client does not have enough equity in the property. To qualify the loan-to-value ratio must not exceed 90%.`,
  sq_footage_check: () =>
    'The property is not within 700 to 5,500 square feet ',
  zoning_description_check: () =>
    'The property type is not a single family home, townhome, or a condo zoned for residential use',
};

export const USE_AUTOMATED_PROPERTY_ELIGIBILITY_QUERY_KEY =
  'USE_AUTOMATED_PROPERTY_ELIGIBILITY_QUERY_KEY';

export const useAutomatedBbysEligibilityCheck = (
  options: UseMutationOptions<
    PropertyEligibilityChecksResponse,
    unknown,
    AutomatedBbysEligibilityCheckArgs | undefined,
    unknown
  > & { onClose?: (result: PropertyEligibilityChecksResponse) => void }
) => {
  const { openModal, closeModal } = useModal();

  const close = (result: PropertyEligibilityChecksResponse) => {
    options.onClose
      ? options.onClose({ ...result, canceled_submission: true })
      : null;
    closeModal();
  };

  const {
    'automated-eligibility-improvements': {
      enabled: automatedEligibilityImprovements,
    },
  } = useFlags(['automated-eligibility-improvements']);

  return useMutation({
    mutationFn: checkAutomatedPropertyEligibility,
    ...options,
    onSuccess: (result) => {
      const issues = Object.keys(result.eligibility_checks || {})
        .filter((k) => !result.eligibility_checks[k as EligibilityCheck])
        .map(
          (issue) =>
            AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_CHECKS_LABELS[
              issue as EligibilityCheck
            ]
        );

      const warnings = Object.keys(result.warning_checks || {})
        .filter((k) => result.warning_checks[k as WarningCheck])
        .map(
          (issue) =>
            AUTOMATED_BBYS_PROPERTY_WARNING_CHECKS_LABELS[issue as WarningCheck]
        );

      const blockers = Object.keys(result.eligibility_blockers || {})
        .filter((k) => result.eligibility_blockers[k as EligibilityBlocker])
        .map(
          (issue) =>
            AUTOMATED_BBYS_PROPERTY_ELIGIBILITY_BLOCKERS_LABELS[
              issue as EligibilityBlocker
            ]
        );

      const denials = Object.keys(result.denial_checks || {}).filter(
        (k) => result.denial_checks[k as DenialCheck]
      );

      if (config.isProduction && blockers.length) {
        openModal(
          <PropertyBlockedModal
            items={blockers}
            onContinue={() => close(result)}
          />
        );
        return;
      } else if (issues.length && !automatedEligibilityImprovements) {
        openModal(
          <PropertyEligibilityModal
            items={issues}
            onContinue={() =>
              options.onSuccess
                ? options.onSuccess(result, undefined, {})
                : null
            }
            onCancel={() => close(result)}
          />
        );
        return;
      } else if (
        warnings.length &&
        denials.length === 0 &&
        automatedEligibilityImprovements
      ) {
        openModal(
          <ImprovedPropertyEligibilityModal
            items={warnings}
            propertyAddress={result.property_address}
            onContinue={() => {
              if (options?.onSuccess) {
                options.onSuccess(
                  { ...result, acknowledged: true },
                  undefined,
                  {}
                );
              }
              closeModal();
            }}
            onCancel={() => close(result)}
          />,
          {
            hideCloseIcon: true,
            enableClose: false,
          }
        );
        return;
      }

      if (options?.onSuccess) {
        options.onSuccess(result, undefined, {});
      }
    },
    onError: (error) => {
      toast('There was an error', { type: 'error' });
      Sentry.captureException(error);

      if (options?.onError) {
        options.onError(error, undefined, {});
      }
    },
  });
};
