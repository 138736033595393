import { House1Icon } from './icons/house-1';
import { House2Icon } from './icons/house-2';
import { House3Icon } from './icons/house-3';
import { RadioSelect } from './icons/radio-select';

export const MultiColorIcon = {
  House1: House1Icon,
  House2: House2Icon,
  House3: House3Icon,
  RadioSelect: RadioSelect,
};
